import { Controller } from "stimulus"
import axios from 'axios'
import { PropertyType } from './mixins/property_type'
import { applyMixins } from '../lib/helper_functions'

export default class extends Controller {

  static targets = [ "getSurveyType", "commercialProperty", "residentialProperty",
                     "developmentProperty", "propertyType", "propertyGdv", "propertyRental",
                     "propertyDescription", "propertyDetailsArea", "surveyTypeDescription",
                     "instructionType", "propType", "customField", "getOfficeListType",
                     "updateOffice", "selectOffice", "surveyPostcode", "propertyInvolvement",
                     "runCheck", "propertyInvolvementComment", "previousInvolvement",
                     "getSearchResult"]
  static values = { url: String, recordId: String }

  connect(){
    applyMixins(this, [PropertyType])
    this.propertyToggleContent("block", "none", "none", "block");
    this.updatePropertyType();
  }

  get url(){
    return this.getSurveyTypeTarget.dataset.url;
  }

  get propertyGdv(){
    return this.propertyGdvTarget;
  }

  get propertyRental(){
    return this.propertyRentalTarget;
  }

  get propertyDescription(){
    return this.propertyDescriptionTarget;
  }

  get propertyDetailsArea(){
    return this.propertyDetailsAreaTarget;
  }

  chooseSurveyTypeList(e){
    this.selectSurveyTypeList(e)
  }

  updatePropertyType(){
    if(this.hasinstructionTypeTarget && this.instructionTypeTarget.value != ''){
      this.updatePropertyTypeOptions(this.instructionTypeTarget)
    }
  }


  chooseOfficeList(e){
    let officeTarget = this.getOfficeListTypeTarget;
    let dataValues = officeTarget.dataset;
    let url = `/accounts/${dataValues.accountId}/instructions/${dataValues.instructionId}/fetch_offices`
    let officeParams = {
      params: { office_list_type: officeTarget.value },
      headers: { 'Content-Type':'application/json', 'Accept':'application/json' }
    }
    axios.get(url, officeParams)
      .then((response)=> {
        this.createOfficeElement(response.data)
      })
  }

  createOfficeElement(data){
    let officeElement = this.selectOfficeTarget;
    let updateButton = this.updateOfficeTarget;
    officeElement.innerHTML = "";
    if (data.length == 0){
      updateButton.disabled = true;
    }
    if (data.length > 0){
      updateButton.disabled = false;
      data.forEach((value, index) => {
        let options = officeElement.appendChild(document.createElement('option'));
        options.value = value.id;
        options.innerHTML = value.name;
      })
    }
  }

  searchCheck(){
    let postcodeTarget = this.surveyPostcodeTarget;
    let searchParams = {
      params: { postcode: postcodeTarget.value, id: this.recordIdValue },
      headers: { 'Content-Type':'application/json', 'Accept':'application/json' }
    }
    this.fetchPropertyDetail(this.urlValue, searchParams)
  }

  fetchPropertyDetail(url, searchParams){
    axios.get(url, searchParams)
      .then((response)=> {
        this.showPropertyFields();
        let data = response.data;
        this.propertyInvolvementTarget.innerHTML = ''
        if (data.no_data_msg != ''){
          this.propertyInvolvementTarget.innerHTML = `<b>${data.no_data_msg}</b>`
        }else {
          this.searchResult(data["instructions"], 'Instruction');
          this.searchResult(data["legacy"], 'Legacy Instruction');
          this.getHistoricalResponse(data);
          this.searchResult(data["quotations"], 'Quotation');
        }
        this.getSearchResultTarget.value = this.propertyInvolvementTarget.innerHTML
      })
  }

  getHistoricalResponse(data){
    if (data['historical'] > 0){
      this.propertyInvolvementTarget.innerHTML += `<b>There is/are ${data['historical']} imported historical records with a matching postcode </b>`
      this.propertyInvolvementTarget.innerHTML += `<a href='${data['link']}' target="_blank">Here</a>`
      this.propertyInvolvementTarget.innerHTML += '\n'
    }
  }

  showPropertyFields(){
    this.propertyInvolvementTarget.classList.add('d-block');
    this.propertyInvolvementCommentTarget.classList.add('d-block');
    this.previousInvolvementTarget.classList.add('d-block');
    this.runCheckTarget.innerHTML = 'Re-run Check'
  }

  searchResult(record, recordType){
    if (record != null && record.length > 0){
      this.propertyInvolvementTarget.innerHTML += `<b>There is/are ${record.length} ${recordType}(s) with a matching postcode :-</b>`
      this.propertyInvolvementTarget.innerHTML += '\n'
      for(let val of record){
        this.propertyInvolvementTarget.innerHTML += `${val["address"]} <a href='${val["link"]}' target="_blank">Here</a>`
        this.propertyInvolvementTarget.innerHTML += '\n'
      }
    }
  }

  validateRunCheckButton(){
    if (!this.getSearchResultTarget.value){
      alert("Please check previous property involvement by clicking on Run Check button");
      event.preventDefault();
    }
  }
}
