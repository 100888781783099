import { Controller } from "stimulus"
import { getMetaValue, requestHeaders } from '../lib/helper_functions'

export default class DragDropController extends Controller {

  dragover(event) {
    event.preventDefault()
    return true
  }

  insertTargetElement(dropTarget, draggedItem, targetElement, event) {
    const positionComparison = dropTarget.compareDocumentPosition(draggedItem)
    const positionPreceding = 2
    const positionFollowing = 4
    if (positionComparison & positionFollowing) {
      targetElement.insertAdjacentElement('beforebegin', draggedItem)
    }
    else if (positionComparison & positionPreceding) {
      targetElement.insertAdjacentElement('afterend', draggedItem)
    }
    event.preventDefault()
  }

  updateFetchedData(content, updateUrl) {
    let headers = requestHeaders
    headers['X-CSRF-Token'] = getMetaValue('csrf-token')

    fetch(updateUrl, {
      headers: headers,
      method: 'POST',
      body: JSON.stringify(content)
    })
      .then(response => response.json())
      .then((data) => {
        let bookmarks = this.getBookmarks()
        this.bookmarkListTarget.innerHTML = data['html_data']
        this.toggleBookmarks(bookmarks)
      })
  }

  getBookmarks() {
    let allElements = document.getElementsByClassName("bookmark-collapse")
    let bookmarks = []
    for (let element of allElements) {
      bookmarks.push(element)
    }
    return bookmarks
  }

  toggleBookmarks(bookmarks) {
    for (let bookmark of bookmarks) {
      let classes = bookmark.classList
      if (!(classes.contains('show'))) {
        let element = document.getElementById(bookmark.id)
        this.collapseBookmark(element)
      }
    }
  }

  collapseBookmark(bookmark) {
    bookmark.className = "bookmark-collapse collapse close-bookmark"
    bookmark.setAttribute("aria-expanded", false)
  }

  expandBookmark(bookmark) {
    bookmark.className = "bookmark-collapse collapse show"
    bookmark.setAttribute("aria-expanded", true)
  }
}
