import { Controller } from 'stimulus'
import axios from 'axios'
import { applyMixins, handleError } from '../lib/helper_functions'
import { smartElement, replaceCarriageWithBrTag } from '../lib/rich_text_helper_functions'
import { FieldsHelper } from './mixins/fields_helper'

export default class extends Controller {
  static values = { instruction: String, account: String, selectedParagraphs: Array}

  static targets = [ 'content', 'descriptionContent', 'selectedCheckboxButton', 'selectCheckboxModal']

  connect() {
    applyMixins(this, [FieldsHelper])
    Array.from(document.getElementsByClassName('field text-editable')).forEach((item) => {
      let fieldId = item.id
      let result = item.innerText
      item.innerHTML = smartElement(result, fieldId)
    })
  }

  url(paragraphId, account) {
    if (account == null) {
      return `/admins/paragraph_libraries/paragraphs/${paragraphId}`
    } else {
      return `/accounts/${account}/paragraph_libraries/paragraphs/${paragraphId}`
    }
  }

  loadElement(multiple, fieldId) {
    let richTextEditor = document.querySelector('trix-editor')
    let element

    // This script determines which field to append the standard paragraph to, based on the provided conditions.
    // There are two options for adding the standard paragraph: Access Notes and Instruction Notes.
    // Access Notes use `this.hasContentTarget`.
    // Instruction Notes use `this.descriptionContentTarget`.
    // The following checks are necessary to ensure the standard paragraph is appended to the correct target field.

    if (multiple === 'false' && this.hasContentTarget){
      element  = this.contentTarget
    } else if (multiple === 'true') {
      element = this.descriptionContentTarget
    } else if (richTextEditor != null) {
      element = richTextEditor
    } else if (fieldId != null) {
      element = document.getElementById(fieldId)
    } 
    return element
  }

  updateElement(element, result, fieldId) {
    let text = this.addNewLine(result)
    if (element.tagName === 'TEXTAREA' || element.tagName === 'INPUT') {
      element.value += ' ' + this.removeTags(result)
    } else if (element.tagName === 'DIV') {
      this.updateFieldText(element, text, fieldId)
    } else {
      element.editor.insertHTML(replaceCarriageWithBrTag(text))
    }
  }

  updateContent(e) {
    let elementId = e.target.dataset.targetElement
    let element = this.loadElement(false, elementId);
    let paragraphContent = this.selectedParagraph()
    this.removeSelectedParagraphs()
    this.updateElement(element, paragraphContent, elementId)
    $('#selectParagraphModal').modal('hide')
  }

  showContent(e) {
    let eventTarget = e.target
    let multiple = eventTarget.getAttribute('data-multiple')
    let fieldId = eventTarget.getAttribute('data-field-content')
    let account = eventTarget.getAttribute('data-account')
    let element = this.loadElement(multiple, fieldId);
    if (eventTarget.selectedIndex === 0) {
      element.value = ''
    }
    let paragraphId = eventTarget.options[e.target.selectedIndex].value
    let url = this.url(paragraphId, account)
    let params = { headers: {'Content-Type' : 'application/json', 'Accept' : 'application/json'} }
    if (eventTarget.value !== '') {
      axios.get(url, params, element)
        .then((response) => {
          let result = new Array()
          result.push(response.data['paragraph']['content'])
          this.updateElement(element, result)
        })
    }
  }

  selectedParagraph() {
    return this.selectedParagraphsValue.map((paragraph) => {
      return Object.values(paragraph)[0]
    })
  }

  removeTags(result) {
    let text = ''
    result.forEach(function(item){
      text += item.replace(/<[^>]*>/g, '') + '\n\n'   
    })
    return text
  }

  addNewLine(result) {
    let text = ''
    result.forEach(function(item){
      if (item.slice(-4) == '<br>') item = item.replace(/<br>\s*$/, '')

      let newLine = (item.slice(-6) == '</div>') ? '<br>' : '<br><br>'
      text += item + newLine
    })   
    return text
  }

  showCheckboxes(e) {
    // event.target will be text/element node on which user clickes
    // event.currentTarget will be span tag which is needed to show the check boxes.
    let text = e.currentTarget.dataset.text
    let smartTextButtonId = e.currentTarget.parentElement.id
    let fieldId = e.currentTarget.dataset.fieldId
    // Replace HTML closing tags with empty string by 'replace(/<\/\w+>/g, '')', before spliting the smart element string with '/'
    // because closing tags also includes '/' in them.
    let options = text.replace(/[|{}|]/g, '').replace(/<\/\w+>/g, '').split('/')
    let checkboxes = this.selectCheckboxModalTarget
    checkboxes.innerHTML = ''
    let selectedCheckboxButton = this.selectedCheckboxButtonTarget
    selectedCheckboxButton.setAttribute('smartTextButton', smartTextButtonId)
    selectedCheckboxButton.setAttribute('fieldId', fieldId)

    options.forEach(function(item, index){
      let option = `<div class="d-flex border-bottom paragraph-data" data-action="click->paragraph#changeBackgroundColor"><input class="position-relative form-check-input d-none" type="checkbox" id="check${index}" name="select-checkbox" value="${item}"><label class="form-check-label w-100 px-3 py-2 pointer-cursor" for="check${index}"><h6 class="mb-0">${item}</h6></label></div>`
      checkboxes.insertAdjacentHTML('beforeend', option);
    })
  }

  // select and auto save smart elements in fields
  selectedCheckboxes(e) {
    let smartTextButtonId =  e.target.getAttribute('smartTextButton')
    let smartTextButton = document.getElementById(smartTextButtonId)
    let selectedSmartText = Array.from(document.querySelectorAll('input[name=select-checkbox]:checked')).map(e => e.value)
    let fieldId = e.target.getAttribute('fieldId')
    smartTextButton.innerHTML = selectedSmartText.slice(0,-1).join(', ').concat(selectedSmartText.length > 1 ? ' and ' : '', selectedSmartText.slice(-1))
    let fieldElement = document.getElementById(fieldId)
    $('#smartResponseModal').modal('hide')
    this.updateField(fieldElement)
  }

  changeBackgroundColor(e) {
    let clickedOption = e.target
    if (clickedOption.checked) {
      clickedOption.parentElement.classList.add('bg-primary', 'text-white')
    } else {
      clickedOption.parentElement.classList.remove('bg-primary', 'text-white')
    }
  }

  // inserting and auto save paragraph data in unlimited_text, multiline_text and text fields
  // insert result in last of field text
  updateFieldText(element, result, fieldId) {
    let elementContent = this.extractContentFromElement(element)
    result = elementContent + result
    result = smartElement(result, fieldId)
    element.innerHTML = replaceCarriageWithBrTag(result)
    //keyup event is used for field validations when standard paragraphs are applied
    element.dispatchEvent(new Event('keyup'))
    this.updateField(element)
  }

  collectParagraphsInOrder(e) {
    let previousCheckedParagraphs = this.selectedParagraphsValue

    if (e.target.checked) {
      let paragraph = {}
      paragraph[e.target.id] = e.target.value
      previousCheckedParagraphs.push(paragraph)
    } else {
      previousCheckedParagraphs = previousCheckedParagraphs.filter(paragraph => Object.keys(paragraph)[0] !== e.target.id)
    }

    this.selectedParagraphsValue = previousCheckedParagraphs
  }

  removeSelectedParagraphs() {
    this.selectedParagraphsValue = []
  }

  // fetch paragraphs associated with paragraphLibrary
  async fetchParagraph(libraryId, mainSelector, account) {
    try {
      const url = account === undefined
        ? `/admins/paragraph_libraries/fetch_paragraph?paragraph_library_id=${libraryId}`
        : `/accounts/${account}/paragraph_libraries/fetch_paragraph?paragraph_library_id=${libraryId}`;
      const response = await fetch(url, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) {
        throw new Error(`${response.status}`);
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      this.addOrAppnedToParagraphSelector(data, mainSelector);
    } catch (error) {
      handleError(error);
    }
  }

  getParagraphFromLibrary(e) {
    let eventTarget = e.target
    this.fetchParagraph(eventTarget.value, e.target, eventTarget.dataset.account)
  }

  // Add new dropdown to select paragraph
  addOrAppnedToParagraphSelector(response, mainSelector) {
    var select = mainSelector.cloneNode(true);
    select.id = `paragraph-selector${mainSelector.dataset.id}`;
    select.classList.add('form-control');
    select.innerHTML = '<option value="">Select paragraph</option>'
    select.dataset.action = "change->paragraph#showContent"

    let paragraphData = response.paragraphs
    paragraphData.forEach(item => {
      var option = document.createElement("option")
      option.text = item.title
      option.value = item.id // Optionally set the value attribute
      select.add(option)
    })

    const selectContainer = mainSelector.parentElement.parentElement.children[1]
    const existingSelect = selectContainer.querySelector('select')
    if (existingSelect) {
      existingSelect.remove()
    }
    selectContainer.appendChild(select)
  }
}
