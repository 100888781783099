export const PropertyType = {

  customPropertyType(event) {
    if(this.propertyTypeTarget.value == "Custom Text"){
      this.customFieldTarget.classList.remove("d-none")
      this.customFieldTarget.disabled = false
      this.customFieldTarget.focus()
    }else {
      if (this.hasCustomFieldTarget){
        this.customFieldTarget.classList.add("d-none")
        this.customFieldTarget.disabled = true
      }
    }
  },

  propertyToggleContent(rentalStyle, gdvStyle, desStyle, detailStyle){
    if (this.hasPropertyRentalTarget){
      this.propertyRental.style.display = rentalStyle
      this.propertyGdv.style.display = gdvStyle
      this.propertyDetailsArea.style.display = detailStyle
    }
  },

  updatePropertyTypeOptions(target) {
    let propertySelectElement, propertyOptions
    propertySelectElement = this.propertyTypeTarget
    switch (target.value) {
      case 'Commercial':
        propertyOptions = this.commercialPropertyTarget.value
        this.removeValidationBedrooms()
        this.propertyToggleContent("block", "none", "block", "none")
        break
      case 'Development':
        propertyOptions = this.developmentPropertyTarget.value
        this.removeValidationBedrooms()
        this.propertyToggleContent("none", "block", "block", "none")
        break
      case 'Residential':
        propertyOptions = this.residentialPropertyTarget.value
        this.addValidationBedrooms()
        this.propertyToggleContent("block", "none", "none", "block")
    }
    this.createPropertyTypeOptions(propertySelectElement, propertyOptions)
  },

  createPropertyTypeOptions(propertySelectElement, propertyOptions) {
    propertySelectElement.options.length = 0
    let emptyValueOptions = propertySelectElement.appendChild(document.createElement('option'))
    emptyValueOptions.value = this.propTypeTarget.value
    emptyValueOptions.innerHTML = this.propTypeTarget.value
    JSON.parse(propertyOptions).forEach((value) => {
      let propOptions = propertySelectElement.appendChild(document.createElement('option'));
      propOptions.value = value;
      propOptions.innerHTML = value;
    })
  },

  removeValidationBedrooms(){
    if (this.hasBedroomsLabelTarget){
      this.bedroomsLabelTarget.classList.remove("mandatory-field")
      this.bedroomsInputTarget.removeAttribute("required")
    }
  },

  addValidationBedrooms() {
    if (this.hasBedroomsLabelTarget){
      this.bedroomsLabelTarget.classList.add("mandatory-field")
      this.bedroomsInputTarget.setAttribute("required", "true")
    }
  },

  selectSurveyTypeList(e) {
    let instructionType = this.getSurveyTypeTarget.value
    let instructionParams = {
      params: { instruction_type: instructionType },
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
    }
    if (this.url === undefined) {
      this.url = e.target.dataset.url
    }
    let url = this.constructUrlWithParams(this.url, instructionParams.params)
    fetch(url, {
      method: 'GET',
      headers: instructionParams.headers
    })
    .then(response => this.handleFetchResponse(response))
    .then(surveyTypeData => this.updateSurveyTypeElements(surveyTypeData))
    .then(() => {
      if (e.target.dataset.flag === undefined) {
        this.updatePropertyTypeOptions(e.target)
      }
    })
    .catch(error => {
      console.error('There has been a problem with your fetch operation:', error)
    })
  },

  constructUrlWithParams(baseUrl, params) {
    let url = new URL(baseUrl)
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    return url
  },

  handleFetchResponse(response) {
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText)
    }
    return response.json()
  },

  updateSurveyTypeElements(surveyTypeData) {
    const surveyTypeElements = document.querySelectorAll('.survey-types')
    surveyTypeElements.forEach(surveyTypeElement => {
      surveyTypeElement.innerHTML = ""

      if (surveyTypeData.length === 0) {
        let surveyTypeOptions = surveyTypeElement.appendChild(document.createElement('option'))
        surveyTypeOptions.value = ""
        surveyTypeOptions.innerHTML = "- no options available -"
      }

      if (surveyTypeData.length > 0) {
        let placeHolder = surveyTypeElement.appendChild(document.createElement('option'))
        placeHolder.innerHTML = "Please select"
        placeHolder.value = ''

        surveyTypeData.forEach(value => {
          let surveyTypeOptions = surveyTypeElement.appendChild(document.createElement('option'))
          surveyTypeOptions.value = value.id
          surveyTypeOptions.innerHTML = value.name
        })
      }
    })
  }

}