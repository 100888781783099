import { Controller } from 'stimulus'
import { toggleClasses, getMetaValue, applyMixins } from '../lib/helper_functions'
import { smartElement, pasteTextWithFormat, replaceCarriageWithBrTag } from '../lib/rich_text_helper_functions'
import { FileUploader } from './mixins/file_uploader'
import { FieldsHelper } from './mixins/fields_helper'
import { UploadsHelper } from './mixins/uploads_helper'
import { CustomEditor } from './mixins/custom_editor'

export default class extends Controller {
  static values = { maxPhotos: Number, instruction: String, account: String }
  static targets = ['updatePhotoForm', 'biggerPhoto', 'photoCaption', 'totalFieldPhotos', 'tableParagraphButton', 'contentEditor', 'hiddenContentInput']

  connect() {
    applyMixins(this, [FileUploader, FieldsHelper, UploadsHelper, CustomEditor])

    if (this.hasContentEditorTarget) {
      this.contentEditorTarget.innerHTML = this.hiddenContentInputTarget.value
    }
  }

  fieldMaxLengthError(e) {
    let fieldId = e.target.dataset.id
    let errorInputBlock = document.getElementById(`error-text-${fieldId}`)
    if (this.textExceedsLimit(e.target)) {
      errorInputBlock.classList.replace('d-none', 'd-block')
    } else {
      errorInputBlock.classList.replace('d-block', 'd-none')
    }
  }

  // To update field values other than unlimited_text, multiline_text, text field
  fieldUpdate(e) {
    let fieldId = e.target.getAttribute('data-id')
    let value = e.target.value
    let content = { field: { value: value, edited: true } }
    let hasErrors = e.target.nextElementSibling?.matches('.parsley-errors-list.filled')
    if ((value != null) && (e.target.name === 'rating' || !hasErrors)) {
      this.updateData(fieldId, this.instructionValue, this.accountValue, content)
    }
  }

  // To update filed with N/A
  updateNotApplicable(e) {
    let field = document.getElementById(`content-${e.target.offsetParent.dataset.fieldId}`)
    let data = this.notApplicableChanges(e, field)
    this.updateData(data[0], this.instructionValue, this.accountValue, data[1])
  }

  uploaderOptions(fieldId, currentPhotosUploaded) {
    //let urlParams = new URLSearchParams(window.location.search)

    return {
      maxNumberOfFiles: (this.maxPhotosValue - currentPhotosUploaded),
      height: 300,
      // quality: urlParams.get('quality'),
      // maxHeight: urlParams.get('maxHeight'),
      modalId: 'updatePhotosModal',
      xhrRequestUrl: `/accounts/${this.accountValue}/instructions/${this.instructionValue}/fields/${fieldId}/refresh_photos`
    }
  }

  updatePhotos(e) {
    let fieldId = e.target.id.split('photofield_')[1]
    let currentPhotosUploaded = e.target.dataset.totalFieldPhotos
    this.totalFieldPhotosTarget.innerHTML = currentPhotosUploaded
    let url = `/accounts/${this.accountValue}/instructions/${this.instructionValue}/fields/${fieldId}/update_photos`
    this.initializeUploader(url, this.uploaderOptions(fieldId, currentPhotosUploaded))
    $('#updatePhotosModal').modal('show')
  }

  updatePhoto(e) {
    let fieldUploadId = e.target.dataset.uploadId
    let fieldId = e.target.dataset.fieldId
    let modalForm = this.updatePhotoFormTarget
    modalForm.action = `/accounts/${this.accountValue}/instructions/${this.instructionValue}/fields/${fieldId}/update_photo/${fieldUploadId}`
    this.photoCaptionTarget.value = e.target.dataset.caption || ''
    this.insertImage(`field_upload_${fieldUploadId}`, this.biggerPhotoTarget)
    $('#updatePhotoModal').modal('show')
  }

  showReportingTableModal(e) {
    let modalId = e.target.dataset.modalId
    $(`#${modalId}`).modal('show')
  }

  addRow(e) {
    let table = document.getElementById(e.target.dataset.tableId)
    let rowCount = table.rows.length
    let cells = table.rows[rowCount - 1].cells
    let newRow = table.insertRow(rowCount)

    Array.from(cells).forEach((cell, idx) => {
      let newRowCell = newRow.insertCell(idx)
      newRowCell.innerHTML= cell.innerHTML
      let cellTextField = newRowCell.querySelector('textarea')
      if (cellTextField) {
        cellTextField.value = ''
        cellTextField.style.height = '30px'
      }
    })
  }

  deleteRow(e) {
    let row = e.target.closest('tr')
    let table = e.target.closest('table')

    if (table.rows.length === 2) {
      let cells = table.rows[1].cells
      Array.from(cells).forEach(cell => {
        let cellTextField = cell.querySelector('input')
        if (cellTextField)
          cellTextField.value = ''
      })
    } else {
      row.remove()
    }
  }

  updateTableFieldChanges(e) {
    let data = e.target.dataset
    let incompletedCount = parseInt(data.incompletedCount)
    let completed = data.completed === 'true'
    this.applyFieldUpdateChanges(data.fieldId, completed, incompletedCount)
  }

  // paste and auto save the data in fields
  pasteTextInField(e) {
    pasteTextWithFormat(e)
    let element = e.currentTarget
    let content = this.extractContentFromElement(element)
    let text = smartElement(content, element.id)
    element.innerHTML = replaceCarriageWithBrTag(text)
    this.updateField(element)
  }

  increaseTextarea(e) {
    let element = e.target
    element.style.height = (element.scrollHeight) + 'px'
  }

  showInsertParagraphButton() {
    if(this.hasTableParagraphButtonTarget) {
      let id = `table-cell-${this.tableParagraphButtonTarget.dataset.id}`
      this.clearTableCellId(id)
      this.toggleButttonAndCellId(id, 'd-none', 'd-block')
    }
  }

  hideInsertParagraphButton() {
    let button = this.tableParagraphButtonTarget
    if(button && event.relatedTarget !== button) {
      this.toggleButttonAndCellId('', 'd-block', 'd-none')
    }
  }

  clearTableCellId(id) {
    // Remove id from table cell if any have, this will work after paragraph is inserted in a cell, after it focus is moved on other cell.
    let tableCell = document.getElementById(id)
    if (tableCell)
      tableCell.id = ''
  }

  toggleButttonAndCellId(id, addClass, removeClass) {
    toggleClasses(this.tableParagraphButtonTarget, addClass, removeClass)
    event.target.id = id
  }
}
