import { getMetaValue, toggleClasses } from '../../lib/helper_functions'

export const FieldsHelper = {
  // To update unlimited_text, multiline_text, text field value
  textFieldUpdate(e) {
    let relatedTarget = e.relatedTarget
    let targetElement =  e.target || e
    let toolbarButtons = Array.from(this.element.querySelectorAll('.button-row div'))
  
    // Check if the relatedTarget is not a toolbar button
    if (!toolbarButtons.includes(relatedTarget)) {
      this.updateTextField(targetElement)
    }
  },

  updateTextField(targetElement) {
    let elementContent = this.extractContentFromElement(targetElement)
    let content = { field: { value: elementContent, edited: true } }
    let hasErrors = targetElement.getAttribute('maxlength') ? this.textExceedsLimit(targetElement) : false
    if (targetElement.innerText != null && !hasErrors) {
      this.updateData(targetElement.dataset.id, this.instructionValue, this.accountValue, content)
    }
  },

  textExceedsLimit(targetElement) {
    return targetElement.innerText.length >= targetElement.getAttribute('maxlength')
  },

  updateData(fieldId, instructionId, accountId, content) {
    fetch(`/accounts/${accountId}/instructions/${instructionId}/fields/${fieldId}`, {
      headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': getMetaValue('csrf-token')
            },
      method: 'PUT',
      body: JSON.stringify(content)
    })
    .then(response => response.json())
    .then((data) => {
      if (data['site_note']) {
        let fieldStatusElement = document.getElementById(`site-note-attempted-${data['field_id']}`)
        this.updateStatusHtmlData(fieldStatusElement, !!data['site_note_value'].length)
      } else {
        this.applyFieldUpdateChanges(data['field_id'], data['completed'], data['incompleted_count'], data['incompleted_required_count'])
      }
    })
  },

  applyFieldUpdateChanges(fieldId, completed, incompletedCount, incompletedRequiredCount) {
    // cannot use target as defined outside controller
    let button = document.getElementById('report-btn')
    button.textContent = `${incompletedCount} o/s fields`
    this.toggleSignoffButton(incompletedRequiredCount)
    this.fieldUpdateViewChanges(fieldId, completed)
  },

  toggleSignoffButton(incompletedRequiredFieldCount) {
    // cannot use target as defined outside controller
    let signoffBtn = document.getElementById('signoff-button')
    if (incompletedRequiredFieldCount === 0) {
      toggleClasses(signoffBtn, 'd-none', 'd-block')
    } else {
      toggleClasses(signoffBtn, 'd-block', 'd-none')
    }
  },

  fieldUpdateViewChanges(fieldId, completed) {
    let fieldStatusElement = document.getElementById(`attempted-${fieldId}`)
    this.updateStatusHtmlData(fieldStatusElement, completed)

    let listFieldLink = document.getElementById(`list-field-${fieldId}`)
    if (listFieldLink)
      completed ? toggleClasses(listFieldLink, 'd-block', 'd-none') : toggleClasses(listFieldLink, 'd-none', 'd-block')

    if (completed)
      this.addEditedBadge(fieldId)
  },

  updateStatusHtmlData(fieldStatusElement, completed) {
    if (completed) {
      toggleClasses(fieldStatusElement, 'text-secondary', 'text-success')
      toggleClasses(fieldStatusElement.firstChild, 'fa-times', 'fa-check')
      fieldStatusElement.lastElementChild.innerHTML = 'Data Saved'
    } else {
      toggleClasses(fieldStatusElement, 'text-success', 'text-secondary')
      toggleClasses(fieldStatusElement.firstChild, 'fa-check', 'fa-times')
      fieldStatusElement.lastElementChild.innerHTML = 'No Data'
    }
  },

  addEditedBadge(fieldId) {
    let editedBadge = document.getElementById(`edited-${fieldId}`)
    let mappedBadge = document.getElementById(`mapped-${fieldId}`)
    let mappedBadgeNextElement = mappedBadge == null ? null : mappedBadge.nextElementSibling
    let nextElementPresent = mappedBadgeNextElement == null || !mappedBadgeNextElement.classList.contains('badge-success')
    if (editedBadge == null && mappedBadge != null && nextElementPresent) {
      $(`<span id="edited-${fieldId}" class="badge badge-success">edited</span>`).insertAfter(mappedBadge)
    }
  },

  // To update unlimited_text, multiline_text field site_note column
  updateSiteNote(e) {
    let relatedTarget = e.relatedTarget
    let targetElement = e.target || e
    let toolbarButtons = Array.from(this.element.querySelectorAll('.button-row div'))
    // Check if the relatedTarget is not a toolbar button
    if (!toolbarButtons.includes(relatedTarget) && (targetElement.innerText != null)) {
      let fieldId = targetElement.getAttribute('data-id')
      let elementContent = this.extractContentFromElement(targetElement)
      let content = { field: { site_note: elementContent } }
      this.updateData(fieldId, this.instructionValue, this.accountValue, content)
    }
  },

  updateField(element) {
    element.dataset.siteNote ? this.updateSiteNote(element) : this.textFieldUpdate(element)
  },

  notApplicableChanges(e, field){
    let checked = e.target.checked
    let fieldId = e.target.getAttribute('data-field-id')
    let value = checked ? 'N/A' : null

    field.disabled = checked
    if (field.type == 'text')
      field.value = value

    e.target.nextElementSibling.innerHTML = checked ? `'N/A' will be applied in the final report.` : 'Not Applicable'
    this.toggleEditableField(checked, field)

    let content = { field: { value: value, in_applicable: checked } }
    return [fieldId, content]
  },

  toggleEditableField(checked, field) {
    if (checked) {
      field.innerText = 'N/A'
      field.contentEditable = 'false' 
      field.setAttribute('readonly', 'readonly')
    } else {
      field.innerText = ''
      field.contentEditable = 'true'
      field.removeAttribute('readonly')
    }
  },

  extractContentFromElement(element) {
    let htmlContent = element.innerHTML
    if (element.attributes.maxlength?.value === '250') {
      return element.innerText
    } else {
      if (this.containsOnlySpacesAndBrTags(htmlContent))
        htmlContent = ''
  
      // Create a temporary element to manipulate the HTML
      let tempElement = document.createElement('div')
      tempElement.innerHTML = htmlContent
  
      // Replace the smart element <span> tags with their innerText
      let spanTags = tempElement.querySelectorAll('span.smart-element')
      Array.from(spanTags).forEach((spanTag) => {
        let innerText = spanTag.innerText
        spanTag.parentNode.replaceChild(document.createTextNode(innerText), spanTag)
      })
      return tempElement.innerHTML
    }
  },

  containsOnlySpacesAndBrTags(inputString) {
    let regex = /^(?:\s*<br\s*\/?>\s*)*$/;
    return regex.test(inputString);
  }
}
